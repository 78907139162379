<template>
  <div class="col-lg-12 deal" ref="position">
    <!-- <img :src="`${imageDomain}/assets/img/hot-offer.png`" alt="hot-offer" class="hot-offer-icon" v-if="!domesticItem && isSalesDeal && !isSalesDealExpired"/> -->
    <hot-offer-icon class="hot-offer-icon" :color="item.badgeColor" :text="item.badgeText" v-if="!domesticItem && isSalesDeal && !isSalesDealExpired"/>
    <p class="hot-offer-text" v-if="item.badgeText">{{item.badgeText}}</p>

    <div class="lesiurpackages_hotelsbox domestic_hotelsbox" :class="isBig && 'big'" v-if="domesticItem && domesticItem.dealType==='domestic'">
      <b-img :src="dealImageSrc"
        width="470"
        height="300"
        :alt="domesticItem.hotelName"
        v-bind="mainProps"
        :onerror="`this.src='${imageDomain}/assets/img/has_no_picture.jpg'`"
      />
      <div class="disc">
        <strong>{{Math.floor(Math.random() * 12) + 9}}%</strong><br />{{ $t("home.discount")}}
      </div>
      <div class="lesiurpackages_hotelstextbox">

        <h3> {{ domesticItem.hotelName }} </h3>
        <p class="item-sub-title">
          <i class="fa fa-calendar-alt"></i> {{$t("booking.dates")}}: <span dir="ltr">{{ domesticItem.from_date }} - {{ domesticItem.to_date }}</span>
        </p>
        <p>

        </p>

        <!-- <a :href="device==='desktop' ? `${item.order}&roomClass=${item.roomClass}`: `${item.mobileOrder}&roomClass=${item.roomClass}`"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a> -->
        <a :href="device === 'desktop' ? domesticItem.order : domesticItem.mobileOrder"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>

        <div class="d-flex w-100 d-inline-block">
          <div class="clock">
            <p><i class="fa fa-question-circle"></i> {{$t("home.what-including-in-price")}}</p>
            <p>
              <i class="fa fa-check"></i>
              {{$t("home.total-night")}}: {{ calcNight(domesticItem.to_date, domesticItem.from_date) }}
              {{ (calcNight(domesticItem.to_date, domesticItem.from_date) > 1) ? $t("home.nights") : $t("home.night") }}
           </p>
            <p><i class="fa fa-check"></i>{{ domesticItem.price_remark }}</p>

          </div>
          <div class="align-items-center" :class="(device==='desktop') && isBig ? 'd-inline-flex': 'd-inline-block'" style="width:30%;">
            <p class="price text-center">
              <span class="currency-symbol">₪</span><strong>{{domesticItem.price}}</strong>
            </p>
            <p style="font-size:0.8rem;"> {{$t("home.total-price-for-the-package")}} </p>
          </div>
        </div>
      </div>
    </div>

    <div class="lesiurpackages_hotelsbox" :class="isBig && 'big'" v-else>
      <b-img
        :src="dealImageSrc"
        width="470"
        height="300"
        :alt="item.hotelName['en']"
        v-bind="mainProps"
        :onerror="`this.src='${imageDomain}/assets/img/has_no_picture.jpg'`"
      />
      <div class="disc" v-if="item.appliedDiscountPercent && !item.isSalesDeal">
        <strong>{{!!item.appliedDiscountPercent && Number(item.appliedDiscountPercent) > 0 ? item.appliedDiscountPercent.toFixed(0) : 0}}%</strong><br />{{ $t("home.discount")}}
      </div>
      <div class="lesiurpackages_hotelstextbox">

        <h3 v-if="item.packageType !== 'F'"> {{ item.hotelName['en'] }} </h3>
        <!-- <div class="d-flex" v-if="item.stars>2" > -->
        <div class="d-flex" v-if="item.stars>2 && item.categoryId !== 'Organize_tour_packages'" >
          <b-form-rating class="ui rating m-auto bg-transparent" variant="warning" inline :value="item.stars" readonly locale="he"></b-form-rating>
        </div>
        <p class="item-sub-title" v-if="item.packageType !== 'F'">
          {{ item.destinationName[lang] }}, {{ item.destinationCountryName[lang] || '' }}
        </p>
        <h3 v-if="item.packageType === 'F'"> {{ item.destinationName[lang] }}, {{ item.destinationCountryName[lang] || '' }} </h3>
        <p class="item-sub-title" v-if="item.packageType === 'F'"></p>
        <p class="item-duration" :dir="lang==='he'? 'rtl': 'ltr'">
          {{`${item.nights} ${$t("home.nights")} / ${Number(item.nights)+1} ${$t("home.days")}`}}
        </p>
        <!-- <a :href="item.selectedPackageUrl"><div class="place btn-primary">{{ $t("home.slide-deal.order-now") }}</div></a> -->
        <a :href="!isSalesDealExpired ? `${item.selectedPackageUrl}${hotelNameParam}${odyAgentCodeParam}&utm_source=${landingInfo.utm_source}${fcAgentModeParam}`: ''" v-if="isLanding"
          :onclick="isSalesDealExpired ? 'return false;':'return true;'">
          <div class="place btn-primary" :class="{'disabled': isSalesDealExpired }">{{ $t("home.slide-deal.order-now") }}</div>
        </a>
        <router-link :to="!isSalesDealExpired ? `${item.selectedPackageUrl}${hotelNameParam}${utmSourceQuery}${odyAgentCodeParam}${fcAgentModeParam}`: '#'" v-else
          :onclick="isSalesDealExpired ? 'return false;':'return true;'">
          <div class="place btn-primary" :class="{'disabled': isSalesDealExpired }" @click="recordLocation">{{ $t("home.slide-deal.order-now") }}</div>
        </router-link>

        <!-- <div class="d-flex w-100 d-inline-block" v-if="item.dealTypeCode !== 'Organize_tour_packages'"> -->
        <div class="d-flex w-100 d-inline-block">
          <div class="clock">
            <p><img class="icon ml-2" :src="`${imageDomain}/assets/img/calendar.png`" width="20" height="20" alt="calendar"/><span dir="ltr">{{ changeDateFormat(item.departureDate) }} - {{ changeDateFormat(item.arrivalDate) }}</span></p>
            <p v-if="item.packageType !== 'F'" ><img class="icon ml-2" :src="`${imageDomain}/assets/img/deal_-_transfers.png`" width="20" height="20" alt="deal-transfer"/>{{ item.transfers }} </p>
            <p v-if="item.packageType !== 'F'"><img class="icon ml-2" :src="`${imageDomain}/assets/img/deal_-_basis.png`" width="20" height="20" alt="deal-basis"/>{{ item.boardBasisName[lang] }}</p>

            <p v-if="item.packageType === 'F'" :dir="lang==='he'? 'rtl': ''">
              <span class="icon"><i class="fas fa-suitcase-rolling"></i></span>
              {{ $t("flight-only.luggage-plane-desc") }}, {{ $t("flight-only.max") }}:
              <span class="weight-number">{{ luggageWeight }}Kg</span>
            </p>
            <p v-if="item.packageType === 'F'" :dir="lang==='he'? 'rtl': ''">
              <span class="icon"><i class="fas fa-briefcase"></i></span>
              {{ $t("flight-only.including-small-desc") }} , {{ $t("flight-only.max") }}:
              <span class="weight-number">{{ handWeight }}Kg</span>
            </p>
          </div>
          <div class="align-items-center" :class="(device==='desktop') && isBig ? 'd-inline-flex': 'd-inline-block'">
            <p class="price text-center">
              <span class="currency-symbol">{{convertCurrencySymbol(item.currenySymbol)}}</span><strong>{{item.packageType === "F" ? item.discountedPriceFO.priceByAge[0].price: item.discountedPrice.price_average}}</strong>
            </p>
            <p class="text-center"> {{ priceRemark }} </p>
          </div>
        </div>
      </div>

    </div>
    <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/>

    <div class="collapse-button">
      <countdown
        :end-time="item.endDate" @finish="finishCountdown" v-if="!domesticItem && isWithTimer">
        <template v-slot:process="time">
          <h5>
            <b-badge variant="success" v-if="time.timeObj.d !== '0'">
              {{ $t("home.count-down-day", { day: time.timeObj.d, hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h !== '00'">
              {{ $t("home.count-down-hour", { hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h === '00' && time.timeObj.m !== '00'">
              {{ $t("home.count-down-minute", { minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else>
              {{ $t("home.count-down-second", { second: time.timeObj.s}) }}
            </b-badge>
          </h5>
        </template>
      </countdown>
    </div>

    <custom-tooltip :content="itineraryProps" v-if="item && item.categoryId === 'Organize_tour_packages'" :isDeal="true" class="tool-tip-button"/>

    <div class="collapse-button">
      <b-badge v-if="isSalesDeal && isSalesDealExpired" variant="warning">{{$t("home.count-down-expired")}}</b-badge>
    </div>

    <b-modal id="share-this-modal" v-model="shareThisModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareData"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>
    <div class="share-button" @click="showShareThisModal">
      <svg xmlns="http://www.w3.org/2000/svg" class="share-icon" viewBox="0 0 512 512">
        <path d="M378,324a69.78,69.78,0,0,0-48.83,19.91L202,272.41a69.68,69.68,0,0,0,0-32.82l127.13-71.5A69.76,69.76,0,1,0,308.87,129l-130.13,73.2a70,70,0,1,0,0,107.56L308.87,383A70,70,0,1,0,378,324Z" fill="#28a745"></path></svg>
    </div>

  </div>
</template>
<script>
import { BImg, BFormRating, BBadge } from 'bootstrap-vue';
import TemplateLesiurtabItem from './TemplateLesiurtabItem';

export default {
  name: 'LesiurTabItemTheme0',
  mixins: [TemplateLesiurtabItem],
  components: {
    BImg,
    BFormRating,
    BBadge,
    CustomTooltip: () => import('@/components/atoms/CustomTooltip'),
    MoreDealCollapse: () => import('@/components/atoms/moreDealCollapse/MoreDealCollapseTheme0'),
    countdown: () => import('@/components/atoms/CountDown'),
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  data() {
    return {
      shareThisModal: false,
    };
  },
  computed: {
    dealImageSrc() {
      const { item } = this;
      const { categoryId } = item;
      let src = '';

      switch (categoryId) {
        case 'Organize_tour_packages':
          src = item.galleryUrl[Math.floor(item.indexNumber % item.galleryUrl.length)] || item.imageUrl || this.getDestinationImage(item.destinationCode) || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
          break;
        case 'Flight_Only':
          src = item.destImageUrls[Math.floor(item.indexNumber % item.destImageUrls.length)] || item.imageUrl || this.getDestinationImage(item.destinationCode) || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
          break;
        default:
          src = item.perspectiveUrl[Math.floor(item.indexNumber % item.perspectiveUrl.length)] || item.imageUrl || item.destImageUrls[0] || this.getDestinationImage(item.destinationCode) || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      }
      if (this.domesticItem && this.domesticItem.dealType === 'domestic') {
        src = this.domesticItem.imgMainUrl || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
      }
      return src;
    },
    shareData() {
      return {
        category: this.item.categoryId,
        dest: this.item.destinationName[this.lang],
        hotel: this.item.hotelName[this.lang],
        image: this.dealImageSrc,
        duration: `${this.item.nights} ${this.$t('home.nights')} / ${Number(this.item.nights) + 1} ${this.$t('home.days')}`,
        link: `${this.item.selectedPackageUrl}`,
        country: this.item.destinationCountryName[this.lang] || '',
        rate: `${this.convertCurrencySymbol(this.item.currenySymbol)}${this.item.packageType === 'F' ? this.item.discountedPriceFO.priceByAge[0].price : this.item.discountedPrice.price_average}`,
      };
    },
  },
  methods: {
    showShareThisModal() {
      this.shareThisModal = true;
    },
  },
};
</script>

<style scoped>
.hot-offer-text {
  position: absolute;
  z-index: 10;
  color: white;
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
.share-button {
  top: 3px !important;
  left: 13px;
}
.more-date-text{
  position: absolute;
  bottom: 25px;
  right: 38px;
  z-index: 10;
  color: white;
}
.collapse-button {
  position: absolute;
  z-index: 3;
  left: 50px;
  top: 10px;
}
.tool-tip-button {
  z-index: 1;
}
/* .collapse-button {
  right: 42%;
} */
/* .col-lg-8 .col-lg-12 .collapse-button {
  right: 20%;
} */
.rating {
  height : 25px;
}
img.icon {
  width: 20px;
}
.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox {
  padding: 5px;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p:first-child{
  margin-top: 0.25rem;
}
.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width:80%;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .item-sub-title{
  text-align:center;
  margin-top: 0;
  font-weight: 600;
}
.item-duration {
  text-align: center;
}
.price{
  padding-top: 0.7rem;
}
.place {
  padding: 5px 10px;
  content: "";
  background: #005ca3;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  z-index: 91;
  width: 120px;
  margin: 5px auto;
  border: 1px solid rgb(255 255 255 / 0.5);
  font-weight: bold;
}
.place.disabled {
  cursor: default;
  opacity: 0.5;
}
.lesiurpackages_hotelstextbox a:hover {
  text-decoration: none;
}
.lesiurpackages_box.Oregenizedbox .lesiurpackages_hotelstextbox p {
  margin-bottom: 20px;
}
.domestic-hotel-name{
  font-weight: bold;
  font-size: 1.3em;
}
.domestic_hotelstextbox{
  background: white;
  padding-bottom: 20px;
}
.last-paragraph{
  border-bottom: 1px solid;
}
.what-description{
  font-weight: bold;
}
.text-color-blue{
  color:#1425f5;
}
.lesiurpackages_hotelsbox.domestic_hotelsbox .place {
  width: fit-content;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width: auto;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
  white-space: break-spaces;
  display: flex;
  margin-bottom: 3px;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox img {
  filter: brightness(0.8);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:hover img {
  filter: brightness(1);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
  font-size: 0.9rem;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock i {
  margin-left: 5px;
}
.img-content{
  overflow: hidden;
  position: relative;
}
.bg-transparent{
  background: none;
  border: none;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
  white-space: nowrap;
}
@media (min-width: 992px) and (max-width: 1200px){
  .lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
    width:70%;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: break-spaces;
    line-height: 0.5rem;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p{
    line-height: 1rem;
  }
}
@media (max-width: 991px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox {
    text-align: center;
  }
}
@media (min-width: 480px) {
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    margin-bottom: 130px;
  }
  .lesiurpackages_hotelsbox.big h3{
    font-size: 3em;
  }
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    font-size: 2em;
  }
  .lesiurpackages_hotelsbox.big .place{
    width: 200px;
    padding: 10px 10px;
    font-size: 1.2rem;
  }
  .lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox .d-inline-block p{
    line-height: 1em;
  }
}

@media (max-width: 480px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
    font-size: 0.9em;
  }
  .lesiurpackages_hotelsbox {
    height: 350px;
  }
  /* .domestic_hotelsbox .domestic_hotelstextbox h4{
    font-size: 1.3rem;
  } */
  .lesiurpackages_hotelsbox.big .currency-symbol{
    font-size: initial;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox{
    padding: 4px
  }
}
.weight-number {
  font-size: 0.8rem;
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -6px;
  right: 9px;
  position: absolute;
  /* transform: rotate(90deg); */
  z-index: 2;
}
</style>

<style>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
    width: 80% !important;
  }
  .domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: normal !important;
  }
  .lesiurpackages_hotelsbox.domestic_hotelsbox .place {
    width: 80% !important;
  }
}
</style>
